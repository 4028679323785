import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+accordion@2.0.28_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3_5jmd4qvdfdm7t6nlmtbhe3ew3m/node_modules/@nextui-org/accordion/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+autocomplete@2.0.9_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss_cvl5ntkcatf6phpt26gw6i7v2i/node_modules/@nextui-org/autocomplete/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+avatar@2.0.24_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4._unru2usnu6rkhyuyzbh3nc2mse/node_modules/@nextui-org/avatar/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+breadcrumbs@2.0.4_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@_l6wcf4h25iqx2glog7bo5zn754/node_modules/@nextui-org/breadcrumbs/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+button@2.0.26_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4._ablztkzql4coezh4mqko55qmi4/node_modules/@nextui-org/button/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+card@2.0.24_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4.1__aqys3okmzwskihndfdellw2bt4/node_modules/@nextui-org/card/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+checkbox@2.0.25_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3._tqo3o4yk3s6vojvo2yofcivsfu/node_modules/@nextui-org/checkbox/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+chip@2.0.25_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4.1__vboymqwf6caa7imenzxsyz24qa/node_modules/@nextui-org/chip/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+dropdown@2.1.16_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3._vxflczqtluc4vmykdoy5dhwi5i/node_modules/@nextui-org/dropdown/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+image@2.0.24_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4.1_5z7wtzmx4kipbdd3witb5fpk4i/node_modules/@nextui-org/image/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+input@2.1.16_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4.1_fgpe2vvxipcwjgonqbhgg7mzci/node_modules/@nextui-org/input/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+link@2.0.26_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4.1__xj43aha47fz4omas3ofjk5stuq/node_modules/@nextui-org/link/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+listbox@2.1.16_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4_wwjqp4mek3adwxdqyurxbrjp4a/node_modules/@nextui-org/listbox/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+menu@2.0.17_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4.1__i6ghs5men5w4mrjd662cuppcwi/node_modules/@nextui-org/menu/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+modal@2.0.28_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4.1_lhwzpmdpn4o6tfr5ptnnrzx3zu/node_modules/@nextui-org/modal/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+navbar@2.0.27_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4._h7wyg6pwdietkshwo33lgdgb7y/node_modules/@nextui-org/navbar/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+pagination@2.0.26_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@_qg2i5fvbbrljwcaiaktkqtkjly/node_modules/@nextui-org/pagination/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+popover@2.1.14_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4_z2a5ucibma5hxfgxlgk7lsolry/node_modules/@nextui-org/popover/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+progress@2.0.24_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3._nkpva2ckmpsmcrcjwoqpvhykxa/node_modules/@nextui-org/progress/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+radio@2.0.25_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4.1_txht6rtzzmf3ikrmfyke5qtqsu/node_modules/@nextui-org/radio/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+ripple@2.0.24_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4._evdvciglxodcl55x3pcnagqpoi/node_modules/@nextui-org/ripple/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+scroll-shadow@2.1.12_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindc_a2fsp4t4ti6amwzsodc67fte4m/node_modules/@nextui-org/scroll-shadow/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+select@2.1.20_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4._vxwdu6mdyl7aixq5dbu2cmoica/node_modules/@nextui-org/select/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+slider@2.2.5_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4.1_e2ei5vqhldeoxpjtqta5kxhd7q/node_modules/@nextui-org/slider/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+snippet@2.0.30_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4_f2bjbn4a3y7fyfuekllogiv5fe/node_modules/@nextui-org/snippet/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+switch@2.0.25_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4._voxhcjr7tsfh73tcimwkupv27i/node_modules/@nextui-org/switch/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+table@2.0.28_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4.1_jhgji2cosremb75fiqcdpzk6r4/node_modules/@nextui-org/table/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+tabs@2.0.26_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4.1__qd2him3xiuq5ybxm53bkfy6ozi/node_modules/@nextui-org/tabs/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+tooltip@2.0.29_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4_gso7tljvqf6dxbwr3ni4w5cjxy/node_modules/@nextui-org/tooltip/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@nextui-org+user@2.0.25_@nextui-org+system@2.0.15_@nextui-org+theme@2.1.17_tailwindcss@3.4.1__cllbbmmo73cr7rje4q5tu2q7au/node_modules/@nextui-org/user/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.4_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.4_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/src/components/business/navigation/templates/business.nav.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/business/navigation/templates/shopping.nav.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/business/navigation/templates/vertical.nav.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/nav-ulife-client-component.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/selections/language-selection.tsx")